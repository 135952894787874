import React, { useContext, useEffect, useState, useRef } from 'react'
import { GameContext } from '../../context/game/gameContext'

const AnswersIntro = ({visible, questioner, onAnswersIntro}) => {
  const {game} = useContext(GameContext)
  const [delay, setDelay] = useState(5000);

  useInterval(() => {
    onAnswersIntro()
    setDelay(null)
  }, delay);

  const getText = () => {
    let text = null

    if (game.stage.id === 1) {
      text = 'Привіт, доросле життя!'
    }
    if (game.stage.id === 2) {
      text = 'Нарешті у мене своя справа!'
    }
    if (game.stage.id === 3) {
      if (game.player.image === 'boy-icon.png') {
        text = 'Завжди мріяв про державну службу!'
      } else {
        text = 'Завжди мріяла про державну службу!'
      }
    }

    return text
  }

  return (<>
      { visible &&
      <div>
        <div className="answers-wrapper">
          <div className="questioner-wrapper show-mobile">
            <div className="img">
              {questioner.image}
            </div>
          </div>
          <div className="stage-intro-text">
            {getText()}
          </div>
        </div>
      </div>
      }</>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default AnswersIntro