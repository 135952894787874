import React, { useContext } from 'react'
import './final.scss'
import { GameContext } from '../../context/game/gameContext'
import Modal from '../modal/Modal'
import { history } from '../../routers/AppRouter'
import tw from '../../assets/images/icon-twitter-96.png'
import fbRound from '../../assets/images/icon-facebook-round-96.png'
import tgRound from '../../assets/images/icon-telegram-round-96.png'
import { CSSTransition } from 'react-transition-group'

function Final ({visible, final}) {
  const {resetGame, playSound} = useContext(GameContext)

  if( final === null) {
    return null
  }

  const shareFb = (e) => {
    e.preventDefault();
    window.FB.ui(
      {
        method: 'share',
        href: window.location.href.slice(0, -5),
        quote: `${final.title}\n${final.message}\nАнтикорупційна карма: ${final.sum}`,
      });
  }

  const newGameClick = (e) => {
    e.preventDefault();
    playSound('clickButton');
    resetGame()
    history.push('/start')
  }

  const renderFinal = () => {
    return (
      <Modal title="" showButton={true} buttonText="Ще раз" clickButton={newGameClick}>
        <div className="final-content">
          <div className="final-title">{final.title}</div>
          <div className="final-message">{final.message}</div>
          <div className="final-params"><span>{final.sum}</span>Антикорупційна карма</div>
          <div className="final-share">
            Поділитися у
            <a onClick={shareFb}><img src={fbRound} /></a>
            <a target="_blank" rel="noopener noreferrer"
               href={`https://twitter.com/intent/tweet?url=${window.location.href.slice(0, -5)}&text=${final.title} ${final.message} Антикорупційна карма: ${final.sum}`}><img src={tw} /></a>
          </div>
          <div className="final-more">
            <div>Будь на зв'язку з USAID_ВзаємоДія</div>
            <div>
              <a href="https://www.facebook.com/ChargeAgainstCorruption/" rel="noopener noreferrer" target="_blank"><img src={fbRound} alt="" /></a>
              <a href="https://t.me/YoungUncorrupt" rel="noopener noreferrer" target="_blank"><img src={tgRound} alt="" /></a>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={'my-node'}
      mountOnEnter
      unmountOnExit
    >
      {renderFinal()}
    </CSSTransition>
  )
}

export default Final;