import React from 'react'
import './achievement.scss'
import starPositive from '../../../src/assets/images/icon-star-positive.png'
import starNegative from '../../../src/assets/images/icon-star-negative.png'
import { CSSTransition } from 'react-transition-group'

function Achievement ({visible, achievement, onClickAchievement}) {
  if(achievement === null ) {
    return null
  }

  const renderAchievement = () => {
    let type = achievement.type;
    let typeClass = 'good';
    let icon = starPositive;
    if (type == 2){
      typeClass = 'bad';
      icon = starNegative;
    }
    return (
      <div className={`achievement-wrapper ${typeClass}`} onClick={onClickAchievement}>
        <div className="achievement-icon"><img src={icon} /></div>
        <div className="achievement-subtitle">Нове досягнення</div>
        <div className="achievement-title">{achievement.title}</div>
        <div className="achievement-text">{achievement.content}</div>
        <a className="achievement-btn">далі</a>
      </div>
    )
  }

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={'my-node'}
      mountOnEnter
      unmountOnExit
    >
      {renderAchievement()}
    </CSSTransition>
  )
}

export default Achievement;