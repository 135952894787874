import React from 'react'
import { CSSTransition } from "react-transition-group";

const Answers = ({visible, question, questioner, onAnswerQuestion}) => {

  const ans = () => (
    question.answers.map(obj => {
      return (
        <div key={obj.id} className="answer" data-id={obj.id} onClick={onAnswerQuestion}>{obj.title}</div>
      )
    })
  )

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={'my-node'}
      mountOnEnter
      unmountOnExit
    >
    <div>
      <h1 style={{display: 'block'}}>{question.title}</h1>
      <div className="answers-wrapper">
        <div className="questioner-wrapper show-mobile">
          <div className="img">
            {questioner.image}
            <div className="title">{questioner.title}</div>
          </div>
        </div>
        <div>
          {ans()}
        </div>
      </div>
    </div>
    </CSSTransition>
  )
}

export default Answers