import React, {useContext, useEffect, useState} from 'react';
import { GameContext } from '../../context/game/gameContext'
import Modal from '../modal/Modal'
import './page.scss'
import umcm from '../../assets/images/logo_ucmc.png'
import usid from '../../assets/images/logo_usaid.png'
import usidVz from '../../assets/images/logo_usaid_vz.png'

function Page () {
  const {playSound, page, resetPage} = useContext(GameContext)
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (page !== null){
      setVisible(true)
    }
  }, [page]);

  const onClickPage = () => {
    playSound('clickButton')
    setVisible(false)
    resetPage()
  }

  if (page === null) {
    return null
  }

  const renderContent = () => {
    if (visible) {
      let images = null;
      if (page.id === '1'){
        images = <div className="images-row">
          <div><img src={usid} /></div>
          <div><img src={usidVz} /></div>
            <div><img src={umcm} /></div>
        </div>
      }
      return (
        <Modal contentClass="page-content" title={page.title} showButton={true} buttonText="Закрити" clickButton={onClickPage}>
          <div dangerouslySetInnerHTML={{__html: page.content}} />
          {images}
        </Modal>
      )
    } else {
      return null
    }
  }

  return renderContent()
}

export default Page;