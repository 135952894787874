import React from 'react'
import './comment.scss'
import { CSSTransition } from 'react-transition-group'

function Comment ({visible, comment, onCommentHide}) {

  if(comment === null) {
    return null
  }

  const c = () => {
    let carmaChange = comment.params.carma;
    if (carmaChange > 0){
      carmaChange = '+' + carmaChange;
    } else if (carmaChange === '0'){
      carmaChange = 0
    }

    return carmaChange
  }

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={'my-node'}
      mountOnEnter
      unmountOnExit
    >
        <div className="comment-wrapper" onClick={onCommentHide}>
          <div className="comment-text">{comment.title}</div>
          { c() !== '0' &&
          <div className="comment-params">Антикорупційна карма <span>{c()}</span></div>
          }
          <a className="comment-btn">далі</a>
        </div>
    </CSSTransition>
  )
}

export default Comment;