import React, {useContext} from 'react';
import { GameContext } from '../../context/game/gameContext'
import './stage.scss';

const Stage= () => {
  const {game} = useContext(GameContext)

  if(game === null) {
    return null
  }

  const getStage = () => {
    let out = null;
    if (game.stage.id !== 1){
      out = <div className="stage active">{game.stage.title}<span>Етап 2</span></div>;
    } else {
      out = <div className="stage"><span>Етап 2</span></div>;
    }
    return out;
  }

  return (
    <div className="stage-widget-wrapper">
      <div className="stage-content-wrapper">
        <div className="stages-wrapper">
          <div className={`stage ${ game.stage.id === 1 ? `active` : ``}`}>Студентство<span>Етап 1</span></div>
          {getStage()}
        </div>
      </div>
    </div>
  )
};

export default Stage;
