import React from 'react'
import './icon.scss'
import boy from '../../../src/assets/images/boy-icon.png'
import boyBiz from '../../../src/assets/images/boy-icon-biz.png'
import boyGov from '../../../src/assets/images/boy-icon-gov.png'
import girl from '../../../src/assets/images/girl-icon.png'
import girlGov from '../../../src/assets/images/girl-icon-gov.png'
import girlBiz from '../../../src/assets/images/girl-icon-biz.png'

const Icon = ({id, stage}) => {

  const getIcon = () => {
    let i = parseInt(id),
      s = parseInt(stage),
      icon = null;

    if (i === 1){
      icon = boy
      if (s === 2){
        icon = boyBiz
      }
      if (s === 3){
        icon = boyGov
      }
    }
    if (i === 2){
      icon = girl
      if (s === 2){
        icon = girlBiz
      }
      if (s === 3){
        icon = girlGov
      }
    }
    return icon
  }

  return (
    <img src={getIcon()} alt="" />
  )
}

export default Icon;