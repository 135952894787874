import {
  SHOW_ALERT,
  HIDE_ALERT,
  GET_PLAYERS,
  SHOW_LOADER,
  SET_PLAYER,
  START_GAME,
  ANSWER_QUESTION,
  SELECT_STAGE,
  TOGGLE_SOUND,
  COOKIE_SOUND,
  GET_PAGE,
  RESET_GAME,
  RESET_COMMENT,
  RESET_PLAYERS,
  SHOW_FORM,
  RESET_PAGE
} from '../types'
import { createCookie, getCookie } from '../../cookie'

const handlers = {
  [SHOW_ALERT]: (state, {payload}) => ({...payload, visible: true}),
  [HIDE_ALERT]: state => ({...state, visible: false}),
  [SHOW_LOADER]: state => ({...state, loading: true}),
  [RESET_COMMENT]: state => ({...state, game: {...state.game, comment: null}}),
  [RESET_GAME]: state => ({...state, game: null}),
  [RESET_PLAYERS]: state => ({...state, players: []}),
  [RESET_PAGE]: state => ({...state, page: null}),
  [TOGGLE_SOUND]: state => {
    createCookie({
      name: 'sound',
      value: !state.sound,
      days: 10
    })
    return ({...state, sound: !state.sound})
  },
  [SHOW_FORM]: state => ({...state, form: !state.form}),
  [COOKIE_SOUND] : state => {
    let st = state.sound,
      cookie = getCookie({name: 'sound'})

    if(cookie === ''){
      createCookie({
        name: 'sound',
        value: st,
        days: 10
      });
    } else if(cookie === 'true'){
      st = true;
    } else if(cookie === 'false'){
      st = false;
    }
    return ({...state, sound: st})
  },
  [GET_PLAYERS]: (state, {payload}) => ({...state, players: payload, loading: false}),
  [GET_PAGE]: (state, {payload}) => ({...state, page: payload}),
  [SET_PLAYER]: (state, {payload}) => ({...state, player: payload}),
  [START_GAME]: (state, {payload}) => ({...state, game: payload, loading: false}),
  [ANSWER_QUESTION]: (state, {payload}) => ({...state, game: payload}),
  [SELECT_STAGE]: (state, {payload}) => ({...state, game: payload, loading: false}),
  DEFAULT: state => state
}

export const gameReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT
  return handle(state, action)
}