import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link } from "react-router-dom";
import hands_back from '../../assets/images/hands-back.png';
import hands_front from '../../assets/images/hands-front.png';
import money from '../../assets/images/money.png';
import no_sign from '../../assets/images/no.png';
import title_sign from '../../assets/images/title.png';
import present from '../../assets/images/present.png';
import city from '../../assets/images/city.png';
import apple from '../../assets/images/apple.png';
import './home.css';
import { GameContext } from '../../context/game/gameContext'

const images = [money, present, apple, city];

function Home() {
  const {playSound, checkSound} = useContext(GameContext)
  let [count, setCount] = useState(1);
  let [delay, setDelay] = useState(6000);
  let [image, setImage] = useState(images[Math.floor(Math.random() * images.length)]);

  useEffect(() => {
    checkSound()
  }, []);

  const getRandomImage = () => {
    const rand = images[Math.floor(Math.random() * images.length)];
    setImage(rand);
  }

  useInterval(() => {
    getRandomImage();
    if (count === 1) {
      setDelay(4000)
    }

    setCount(count + 1);
  }, delay);

  return (
    <div className="page-wrapper home-page-wrapper">
      <div className="hands">
        <div className="hand-parts hand-back"><img src={hands_back} alt="" /></div>
        <div className="hand-parts money"><img src={image} alt="" /></div>
        <div className="hand-parts no-sign"><img src={no_sign} alt="" /></div>
        <div className="hand-parts hand-front"><img src={hands_front} alt="" /></div>
        <div className="hand-parts title-sign shift-top"><img src={title_sign} alt="" /></div>
        <Link className="start-game shift-top" to="/start" onClick={() => {playSound('clickButton')}}>Почати гру</Link>
      </div>
    </div>
  );
}

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default Home;
