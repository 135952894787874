import React from 'react'
import { Email, Item, Span, renderEmail } from 'react-html-email'

function EmailHTML() {
  return renderEmail(
    <Email title="Hello World!">
      <Item align="center">
        <Span fontSize={20}>
          This is an example email made with
        </Span>
      </Item>
    </Email>
  )
}

export default EmailHTML