import React, { useEffect, useState } from "react";

const Fade = ({ show, duration, delay, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  const getDelay = () => {
    let d = '0ms';
    if (delay) {
      d = delay
    }
    return d
  };

  const getDuration = () => {
    let d = '100ms';
    if (duration) {
      d = duration
    }
    return d
  };

  const getAnimation = () => {
    if (show) {
      return (
        `fIn linear ${getDuration()}`
      )
    } else {
      return (
        `fOut linear ${getDuration()} ${getDelay()}`
      )
    }
  }

  return (
    shouldRender && (
      <div
        style={{ animation: getAnimation() }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

export default Fade;