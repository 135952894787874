import React, {useEffect, useContext} from 'react';
import Spinner from '../../components/Spinner/Spinner'
import University from '../../components/bg/university/University'
import Modal from '../../components/modal/Modal'
import './character.css';
import { GameContext } from '../../context/game/gameContext'
import {history} from '../../routers/AppRouter'
import Icon from '../../components/player/Icon'
import Images from '../../components/preload/Images'

function Character() {
  const {loading, getPlayers, players, player, setPlayer, playSound, checkSound, resetPlayers} = useContext(GameContext)

  useEffect(() => {
    checkSound()
    getPlayers()
  }, []);

  if (players.length === 0) {
    return null
  }

  const onPlayerSelect = (e) => {
    playSound('playerSelect');

    const pl = players.filter((obj) => {
      return obj.id === e.currentTarget.dataset.id
    })

    setPlayer(pl[0]);
  };

  const onGameStart = (e) => {
    playSound('clickButton')
    resetPlayers()
    history.push('/game')
  }

  const renderPlayers = () => {
    let playerComponent = players.map(obj => {
      let cl = 'character-icon character';
      if (player && player.id === obj.id){
        cl += ' selected'
      }
      return (
        <div className={cl} key={obj.id} data-id={obj.id} onClick={onPlayerSelect}>
          <div className="icon">
            <Icon id={obj.id} stage={1} />
          </div>
          <div className="character-title">{obj.title}</div>
        </div>
      )
    });
    return <>
      <University blur={true} />
      <Modal title="Вибери героя" showButton={player !== null} buttonText="Розпочати" clickButton={onGameStart}>
        <div className="character-selection-wrapper">
          {playerComponent}
        </div>
        <div className="character-desc">
          Тобі 18 років. Перед тобою стелиться життєвий шлях з різними ситуаціями. Подивимося, як ти з ними впораєшся.
        </div>
      </Modal>
    </>
  }

  return (<>
    {loading
      ? <div className="page-wrapper"><Spinner /><Images/></div>
      : <div className="page-wrapper">{renderPlayers()}</div>
    }
  </>);
}

export default Character;
