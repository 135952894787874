import React, {useContext} from 'react';
import { GameContext } from '../../context/game/gameContext'
import starPositive from '../../../src/assets/images/icon-star-positive.png'
import starNegative from '../../../src/assets/images/icon-star-negative.png'
import './panel.scss'
import Icon from '../player/Icon'
import ReactTooltip from 'react-tooltip'

const Panel = () => {
  const {player, game} = useContext(GameContext)

  if(player === null || game === null) {
    return null
  }

  const carma = game.params.carma;
  const carmaHeight = carma + 50;

  const age = Math.ceil(game.age);
  const ageHeight = age + 10;

  const positiveAchievements = game.player.achievements[1].map(obj => {
    return (
      <div key={obj.title}><img src={starPositive} width={30} data-tip data-for={obj.title} /><ReactTooltip id={obj.title} class='tooltip-pos'>{obj.title}</ReactTooltip></div>
    )
  })

  const negativeAchievements = game.player.achievements[2].map(obj => {
    return (
      <div key={obj.title}><img src={starNegative} width={30} data-tip data-for={obj.title} /><ReactTooltip id={obj.title} class='tooltip-neg'>{obj.title}</ReactTooltip></div>
    )
  })

  return (
    <div className="panel-wrapper">
      <div className="character-icon character">
        <div className="icon">
          <Icon id={player.id} stage={game.stage.id} />
        </div>
      </div>

      <div className="progress-bars">
        <div className="progress-bar-wrapper">
          <div className="progress progress-bar-vertical">
            <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style={{height: carmaHeight + '%'}}>
              <span className="sr-only">{carma}</span>
            </div>
          </div>
          <div className="progress progress-bar-horizontal">
            <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style={{width: carmaHeight + '%'}}>
              <span className="sr-only">{carma}</span>
            </div>
          </div>
          <div className="legend carma">Антикорупційна Карма</div>
        </div>
        <div className="achievements-clmn-wrapper">
          <div className="achievement-clmn achievement-clmn-positive">
            {positiveAchievements}
          </div>
          <div className="achievement-clmn achievement-clmn-negative">
            {negativeAchievements}
          </div>
        </div>
        <div className="progress-bar-wrapper">
          <div className="progress progress-bar-vertical">
            <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style={{height: ageHeight + '%'}}>
              <span className="sr-only">{age}</span>
            </div>
          </div>
          <div className="progress progress-bar-horizontal">
            <div className="progress-bar progress-bar-success progress-bar-striped active" role="progressbar" style={{width: ageHeight + '%'}}>
              <span className="sr-only">{age}</span>
            </div>
          </div>
          <div className="legend">Вік</div>
        </div>
      </div>

    </div>
  )
};

export default Panel;
