import React, {useContext} from 'react'
import { GameContext } from '../../context/game/gameContext'
import './toolbar.scss'
import soundOff from '../../assets/images/icon-no-audio-50.png'
import soundOn from '../../assets/images/icon-sound-50.png'
import help from '../../assets/images/icon-help-60.png'
import list from '../../assets/images/icon-bulleted-list-50.png'
import message from '../../assets/images/icon-envelope-48.png'
import ReactTooltip from 'react-tooltip'

const Toolbar = () => {
  const {sound, toggleSound, getPage, toggleForm} = useContext(GameContext)

  const onSoundClick = (e) => {
    e.preventDefault()
    toggleSound()
  }

  const onPageHelp = (e) => {
    e.preventDefault()
    getPage(1)
  }
  const onPageRules = (e) => {
    e.preventDefault()
    getPage(2)
  }

  const onForm = (e) => {
    e.preventDefault()
    toggleForm(2)
  }

  const getIcon = () => {
    let icon = soundOn;
    let sign = 'Вимкнути звук'
    if (!sound) {
      icon = soundOff;
      sign = 'Увімкнути звук'
    }
    return <img data-tip={sign} src={icon} />
  }

  return (<div className="toolbar">
    <a className="sound-toggle" href="" onClick={onSoundClick}>{getIcon()}</a>
    <a href="" onClick={onPageHelp}><img data-tip="Пам’ятка" src={help} /></a>
    <a href="" onClick={onPageRules}><img data-tip="Правила гри" src={list} /></a>
    <a href="" onClick={onForm}><img data-tip="Зворотній зв’язок" src={message} /></a>
    <ReactTooltip class="tooltip-theme" />
  </div>)
}
export default Toolbar;