import React from 'react'
import './modal.scss'

function Modal ({title, children, showButton, buttonText, clickButton, contentClass, showClose, clickClose}) {
  return (
    <div className="modal">
      <div className={`modal-content ${contentClass}`}>
        <div className="modal-header">
          { showClose &&
            <span className="close" onClick={clickClose}>&times;</span>
          }
          <h2>{title}</h2>
        </div>
        <div className="modal-body">
          {children}
        </div>
        { showButton &&
          <div className="modal-footer">
            <a className="start-game-btn" onClick={clickButton}>{buttonText}</a>
          </div>
        }
      </div>
    </div>
  )
}

export default Modal;