import React from 'react';

function NotFoundPage() {
  return (
    <div>
      Not found
    </div>
  );
}

export default NotFoundPage;
