import React from 'react'
import './effect.scss'
import { CSSTransition } from 'react-transition-group'

function Effect ({visible, effect, onClickEffect}) {

  if(effect === null ) {
    return null
  }

  const renderEffect = () => {
    let carmaChange = effect.params.carma;
    if (carmaChange > 0){
      carmaChange = '+' + carmaChange;
    }
    return (
      <div className="effect-wrapper" onClick={onClickEffect}>
        <div className="effect-text">{effect.title}</div>
        { carmaChange !== '0' &&
        <div className="effect-params">Антикорупційна карма <span>{carmaChange}</span></div>
        }
        <a className="effect-btn">далі</a>
      </div>
    )
  }

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={'my-node'}
      mountOnEnter
      unmountOnExit
    >
      {renderEffect()}
    </CSSTransition>
  )
}

export default Effect;