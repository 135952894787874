import React, {useContext} from 'react';
import { GameContext } from '../../context/game/gameContext'
import './stage.scss';
import Icon from '../player/Icon'
import { CSSTransition } from 'react-transition-group'

const StageSelect = ({visible, stage, onStageSelect}) => {
  const {player} = useContext(GameContext)

  if(stage === null) {
    return null
  }

  const answers = () => (
    stage.map(obj => {
      return (
        <div key={obj.id} className="character-icon character" data-id={obj.id} onClick={() => onStageSelect(obj.id)}>
          <div className="icon">
            <Icon id={player.id} stage={obj.id} />
          </div>
          <div className="character-title">{obj.title}</div>
        </div>
      )
    })
  )

  return (
    <CSSTransition
      in={visible}
      timeout={300}
      classNames={'my-node'}
      mountOnEnter
      unmountOnExit
    >
      <div className="stage-wrapper">
        <div className="stage-content-wrapper">
          <h1>Оберіть подальший шлях</h1>
          <div className="character-selection-wrapper">{answers()}</div>
        </div>
      </div>
    </CSSTransition>
  )
};

export default StageSelect;
