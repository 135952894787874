import React, {useContext, useState} from 'react';
import { GameContext } from '../../context/game/gameContext'
import Modal from '../modal/Modal'
import EmailHTML from '../../components/email/EmailHTML'
import './form.scss'

function Form () {
  const {playSound, form, toggleForm} = useContext(GameContext)
  const def = {
    name: '', feedback: '', region: '', age: '', email: ''
  }
  const [message, setMessage] = useState('');
  const [load, setLoad] = useState(false);
  const [values, setValues] = useState(def);
  const [errors, setErrors] = useState(def);

  const onClickPage = () => {
    if (load) {
      return false
    }
    setLoad(true)
    playSound('clickButton')
    let hasError = false;
    // toggleForm()
    let er = {
      name: '', feedback: '', region: '', age: '', email: ''
    }

    if (values.name === '') {
      er.name = 'error'
      hasError = true
    }

    if (values.feedback === '') {
      er.feedback = 'error'
      hasError = true
    }

    if (values.region === '') {
      er.region = 'error'
      hasError = true
    }

    if (values.age === '') {
      er.age = 'error'
      hasError = true
    }

    setErrors(er)

    if (!hasError) {
      // send email and
      const tpl = <EmailHTML {...values} />
      setMessage('Відправляємо...')

      // eslint-disable-line
      window.emailjs.send(
        'zoho', 'template_EyStBosI',
        values
      ).then(res => {
        setValues(def)
        setLoad(false)

        setMessage('Відправлено')

        setTimeout(() => {
          closeForm();
        }, 1000);

      }).catch(err => console.error('Error:', err))

    } else {
      setLoad(false)
    }
  }

  const handleNameChange = (event) => {
    setValues({...values, name: event.target.value})
  }
  const handleFeedbackChange = (event) => {
    setValues({...values, feedback: event.target.value})
  }
  const handleRegionChange = (event) => {
    setValues({...values, region: event.target.value})
  }
  const handleAgeChange = (event) => {
    setValues({...values, age: event.target.value})
  }
  const handleEmailChange = (event) => {
    setValues({...values, email: event.target.value})
  }

  const closeForm = () => {
    toggleForm()
    setValues(def)
    setErrors(def)
    setMessage('')
    setLoad(false)
  }

  if (form === false) {
    return null
  }

  const renderContent = () => {
    return (
      <Modal contentClass="page-content" title="Зворотній зв’язок" showButton={true} buttonText="Відправити" clickButton={onClickPage} showClose={true} clickClose={closeForm}>
        <form className="feedback-form">
          <div className="form-row">
            <label>Ім'я</label>
            <input id="name" name="name" placeholder="Твоє Ім'я" onChange={handleNameChange} value={values.name} className={`form-control ${errors.name}`} />
          </div>
          <div className="form-row">
            <label>Регіон проживання</label>
            <input id="region" name="region" placeholder="Твій регіон проживання" onChange={handleRegionChange} value={values.region} className={`form-control ${errors.region}`} />
          </div>
          <div className="form-row">
            <label>Вік</label>
            <input id="age" name="age" placeholder="Твій вік" onChange={handleAgeChange} value={values.age} className={`form-control ${errors.age}`}/>
          </div>
          <div className="form-row">
            <label>Email</label>
            <input id="email" name="email" type="email" placeholder="Твій е-мейл для зворотного зв'язку" onChange={handleEmailChange} value={values.email} className={`form-control ${errors.email}`}/>
          </div>
          <div className="form-row">
            <label>Твої враження</label>
            <textarea id="feedback" name="feedback" onChange={handleFeedbackChange} required rows={3} value={values.feedback} className={`form-control ${errors.feedback}`} placeholder="Напиши нам про свої враження від гри"/>
          </div>
        </form>
        {message &&
          <p>{message}</p>
        }
      </Modal>
    )
  }

  return renderContent()
}

export default Form;