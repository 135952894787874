import React from 'react'
import './university.scss'
import bg from '../../../assets/images/university-bg.png'
import house_l from '../../../assets/images/university-house-l.png'
import house_r from '../../../assets/images/university-house-r.png'
import house_c from '../../../assets/images/university-house-c.png'
import tree_l from '../../../assets/images/university-tree-l.png'
import tree_r from '../../../assets/images/university-tree-r.png'
import Fade from '../../fade/Fade'

function University ({blur}) {
  return(
    <div className="university-bg-wrapper" style={{filter: blur ? 'blur(10px)' : 'none'}}>
      <Fade show={true} duration="1000ms">
        <div className="university-parts university-bg"><img src={bg} alt="" /></div>
        <div className="university-parts university-house-l"><img src={house_l} alt="" /></div>
        <div className="university-parts university-house-r"><img src={house_r} alt="" /></div>
        <div className="university-parts university-house-c"><img src={house_c} alt="" /></div>
        <div className="university-parts university-tree-l"><img src={tree_l} alt="" /></div>
        <div className="university-parts university-tree-r"><img src={tree_r} alt="" /></div>
      </Fade>
    </div>
  )
}

export default University;