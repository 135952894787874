import React, {useEffect} from 'react'

import boyfull from '../../assets/images/boy-icon-full.png'
import boybizfull from '../../assets/images/boy-icon-biz-full.png'
import boygovfull from '../../assets/images/boy-icon-gov-full.png'
import girlfull from '../../assets/images/girl-icon-full.png'
import girlbizfull from '../../assets/images/girl-icon-biz-full.png'
import girlgovfull from '../../assets/images/girl-icon-gov-full.png'

import govbg from '../../assets/images/gov-bg.jpg'
import govhouse_r from '../../assets/images/gov-house-r.png'
import govhouse_c from '../../assets/images/gov-house-c.png'

import businessbg from '../../assets/images/business-bg.jpg'
import businesshouse_l from '../../assets/images/business-house-l.png'
import businesshouse_r from '../../assets/images/business-house-r.png'
import businesshouse_c from '../../assets/images/business-house-c.png'
import businesstree_l from '../../assets/images/business-tree-l.png'
import businesstree_r from '../../assets/images/business-tree-r.png'

const images = [
  boyfull, boybizfull, boygovfull, girlfull,girlbizfull, girlgovfull, govbg, govhouse_r, govhouse_c,
  businessbg, businesshouse_l, businesshouse_r, businesshouse_c, businesstree_l, businesstree_r
]

function Images() {
  useEffect(() => {
    images.map(function (im) {
      const img = new Image();
      img.src = im;
    })
  }, []);

  return null
}

export default Images