export const SHOW_ALERT = 'SHOW_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'
export const SHOW_LOADER = 'SHOW_LOADER'
export const GET_PLAYERS = 'GET_PLAYERS'
export const SET_PLAYER = 'SET_PLAYER'
export const START_GAME = 'START_GAME'
export const RESET_GAME = 'RESET_GAME'
export const ANSWER_QUESTION = 'ANSWER_QUESTION'
export const SELECT_STAGE = 'SELECT_STAGE'
export const TOGGLE_SOUND = 'TOGGLE_SOUND'
export const COOKIE_SOUND = 'COOKIE_SOUND'
export const GET_PAGE = 'GET_PAGE'
export const SHOW_FORM = 'SHOW_FORM'
export const RESET_COMMENT = 'RESET_COMMENT'
export const RESET_PLAYERS = 'RESET_PLAYERS'
export const RESET_PAGE = 'RESET_PAGE'