import React from 'react'
import './gov.scss'
import bg from '../../../assets/images/gov-bg.jpg'
import house_r from '../../../assets/images/gov-house-r.png'
import house_c from '../../../assets/images/gov-house-c.png'
import Fade from '../../fade/Fade'

function Gov ({blur}) {
  return(
    <div className="gov-bg-wrapper" style={{filter: blur ? 'blur(10px)' : 'none'}}>
      <Fade show={true} duration="1000ms">
        <div className="gov-parts gov-bg"><img src={bg} alt="" /></div>
        <div className="gov-parts gov-house-r"><img src={house_r} alt="" /></div>
        <div className="gov-parts gov-house-c"><img src={house_c} alt="" /></div>
      </Fade>
    </div>
  )
}

export default Gov;