import React from 'react'

const Questioner = ({visible, questioner}) => {

  const out = () => {
    if (visible) {
      return (
        <div className="img">
          {questioner.image}
          <div className="title">{questioner.title}</div>
        </div>
      )
    } else {
      return null
    }
  }

  return out()
}

export default Questioner