import React, { useContext, useEffect, useState } from 'react'
import { GameContext } from '../../context/game/gameContext'
import './question.scss';
import config from '../../config'
import Comment from '../comment/Comment'
import Stage from '../stage/Stage'
import Achievement from '../achievement/Achievement'
import Effect from '../effect/Effect'
import Answers from './Answers'
import Questioner from './Questioner'
import AnswersIntro from './AnswersIntro'
import boy from '../../assets/images/boy-icon-full.png'
import girl from '../../assets/images/girl-icon-full.png'
import boy_biz from '../../assets/images/boy-icon-biz-full.png'
import girl_biz from '../../assets/images/girl-icon-biz-full.png'
import boy_gov from '../../assets/images/boy-icon-gov-full.png'
import girl_gov from '../../assets/images/girl-icon-gov-full.png'

const questionDefault = {answers: []}
const questionerDefault = {}

const Question = ({showFinal, showStageSelect, togglePanel}) => {
  const {game, answerQuestion, playSound} = useContext(GameContext)
  const [ans, setAns] = useState(false);
  const [qr, setQr] = useState(false);
  const [qri, setQri] = useState(false);
  const [question, setQuestion] = useState(questionDefault);
  const [questionerMob, setQuestionerMob] = useState(questionerDefault);
  const [questioner, setQuestioner] = useState({});
  const [comm, setComm] = useState(false);
  const [ach, setAch] = useState(false);
  const [eff, setEff] = useState(false);

  useEffect(() => {
    if (game.stage_intro){
      setQuestioner(getQuestioner(true))
      setQuestionerMob(getQuestioner(true))

      togglePanel(false)
      setQri(true)
      setQr(false)
    } else if (game.question !== null) {
      showQuestion()
    }
  }, []);

  const showQuestion = () => {
    setQuestioner(getQuestioner())
    setQuestionerMob(getQuestioner())
    setQr(true)

    setQuestion(game.question)
    setAns(true)
  }

  const getQuestioner = (stageMode) => {
    let title, img
    if (stageMode) {
      title = ''
      img = getIntroImage()
    } else {
      title = game.question.questioner.title
      img = <img src={`${config.apiUrl}/mediafiles/${game.question.questioner.image}`} alt="" />
    }

    return {
      title: title,
      image: img
    }
  }

  const getIntroImage = () => {
    let img = null
    if (game.stage.id === 1) {
      switch (game.player.image){
        case 'boy-icon.png':
          img = boy
          break;
        case 'girl-icon.png':
          img = girl
          break;
      }
    }
    if (game.stage.id === 2) {
      switch (game.player.image){
        case 'boy-icon.png':
          img = boy_biz
          break;
        case 'girl-icon.png':
          img = girl_biz
          break;
      }
    }
    if (game.stage.id === 3) {
      switch (game.player.image){
        case 'boy-icon.png':
          img = boy_gov
          break;
        case 'girl-icon.png':
          img = girl_gov
          break;
      }
    }

    return <img src={img} alt=""/>
  }

  const onAnswerQuestion = (e) => {
    setAns(false)
    setQuestion(questionDefault)
    setQuestionerMob(questionerDefault)

    setComm(true)

    playSound('clickButton')
    const id = e.currentTarget.dataset.id;

    answerQuestion(game.hash, id, game.stage.id);
  };

  const onCommentHide = (e) => {
    setComm(false)

    if (game.question !== null) {
      setQuestion(game.question)
      setAns(true)
      setQuestioner(getQuestioner())
      setQuestionerMob(getQuestioner())
      setQr(true)
    }

    if (game.achievement !== null) {
      setAch(true)
      if (game.achievement.type == 2){
        playSound('negativeAch')
      } else {
        playSound('positiveAch')
      }
      playSound('positiveAch')
    } else if (game.effect !== null) {
      if (game.effect.params.carma > 0){
        playSound('positiveAch')
      } else {
        playSound('negativeAch')
      }
      setEff(true)
    } else if (game.stage_select !== null){
      showStageSelect()
    } else if (game.final !== null){
      showFinal()
    }

    playSound('clickButton')
  }

  const onClickAchievement = () => {
    setAch(false)

    if (game.effect !== null) {
      if (game.effect.params.carma > 0){
        playSound('positiveAch')
      } else {
        playSound('negativeAch')
      }
      setEff(true)
    } else if (game.stage_select !== null){
      showStageSelect()
    } else if (game.final !== null){
      showFinal()
    }
  }

  const onAnswersIntro = () => {
    togglePanel(true)
    setQri(false)
    showQuestion()
  }

  const onClickEffect = () => {
    setEff(false)
  }

  return (
    <div className={`question-wrapper ${qri ? `intro-wrapper` : `` }`}>
      <div className="questioner-wrapper hide-mobile">
        <Questioner visible={qr} questioner={questioner} />
        <Questioner visible={qri} questioner={questioner} />
      </div>
      <div className="col">
        <div className="question-content-wrapper">
          <AnswersIntro visible={qri} questioner={questioner} onAnswersIntro={onAnswersIntro}/>
          <Answers visible={ans} question={question} questioner={questionerMob} onAnswerQuestion={onAnswerQuestion} />
          <Comment visible={comm} comment={game.comment} onCommentHide={onCommentHide}/>
        </div>
        { !qri &&
          <Stage />
        }
      </div>
      <Achievement visible={ach} achievement={game.achievement} onClickAchievement={onClickAchievement} />
      <Effect visible={eff} effect={game.effect} onClickEffect={onClickEffect} />
    </div>
  )
};

export default Question;
