import React from 'react';
import { BrowserRouter } from "react-router-dom";
import AppRouter from "./routers/AppRouter";
import { GameState } from './context/game/GameState'
import { FacebookProvider, Initialize } from 'react-facebook'

function App() {
  return (
    <GameState>
      <BrowserRouter>
        <FacebookProvider appId="496651067636987">
          <Initialize>
            <AppRouter />
          </Initialize>
        </FacebookProvider>
      </BrowserRouter>
    </GameState>
  );
}

export default App;
