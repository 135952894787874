import React from "react";
import { Router, Route, Switch, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import routes from "./routes";

export const history = createBrowserHistory();

function TransitionContainer({location}) {
  return (
    <TransitionGroup className="transition-group">
      <CSSTransition
        key={location.key}
        timeout={{ enter: 300, exit: 300 }}
        classNames={'fade'}
      >
        <section className="route-section">
          <Switch location={location}>
            {routes.map(route => (
              <Route key={Math.random()} {...route} />
            ))}
          </Switch>
        </section>
      </CSSTransition>
    </TransitionGroup>
  );
}

const Container = withRouter(TransitionContainer);

const AppRouter = () => {
  return (
    <Router history={history}>
      <Container/>
    </Router>
  )
};

export default AppRouter;
