import React, {useContext, useEffect, useState} from 'react';
import './game.scss';
import { GameContext } from '../../context/game/gameContext'
import {history} from '../../routers/AppRouter'
import University from '../../components/bg/university/University'
import Panel from '../../components/panel/Panel'
import Page from '../../components/page/Page'
import Form from '../../components/form/Form'
import Question from '../../components/question/Question'
import Spinner from '../../components/Spinner/Spinner'
import StageSelect from '../../components/stage/StageSelect'
import Business from '../../components/bg/business/Business'
import Gov from '../../components/bg/gov/Gov'
import Final from '../../components/final/Final'
import Toolbar from '../../components/toolbar/Toolbar'

function Game() {
  const {player, startGame, game, loading, checkSound, playSound, selectStage} = useContext(GameContext)
  const [fin, setFin] = useState(false);
  const [ss, setSs] = useState(false);
  const [pn, setPn] = useState(true);

  useEffect(() => {
    checkSound()
  }, []);
  useEffect(() => {
    if (player === null) {
      history.push('/start')
    } else {
      startGame(player.id)
    }
  }, []);

  const togglePanel = (st) => {
    setPn(st)
  }

  const getBg = () => {
    if (game === null) {
      return null;
    }

    let bg = <University />;
    switch (game.stage.id) {
      case 1:
        bg = <University />
        break;
      case 2:
        bg = <Business />
        break;
      case 3:
        bg = <Gov />
        break;
      default:
        bg = <University />
        break;
    }
    return bg;
  }

  const showFinal = () => {
    if (game.final.type == 1) {
      playSound('positiveFinal')
    } else if (game.final.type == 2) {
      playSound('negativeFinal')
    }
    setFin(true)
  }

  const showStageSelect = () => {
    setSs(true)
  }

  const onStageSelect = (id) => {
    playSound('playerSelect')
    // const id = e.currentTarget.dataset.id;

    selectStage(game.hash, id);
    setSs(false)
  }

  const renderContent = () => {
    let cont = null;
    if (game !== null && fin) {
      cont = <Final visible={fin} final={game.final} />
    } else if (game !== null && ss) {
      cont = <>
        {getBg()}
        <div className="game-wrapper">
          <StageSelect visible={ss} stage={game.stage_select} onStageSelect={onStageSelect}/>
          <Panel />
        </div>
      </>
    } else if (game !== null) {
      cont = <>
        {getBg()}
        <div className="game-wrapper">
          <Question showFinal={showFinal} showStageSelect={showStageSelect} togglePanel={togglePanel}/>
          { pn &&
            <Panel />
          }
        </div>
      </>
    } else {
      cont = null
    }

    return cont;
  }

  return (<>
    {loading
      ? <div className="page-wrapper"><Spinner /></div>
      : <div className="page-wrapper">
        {<Toolbar />}
        {<Page />}
        {<Form />}
        {renderContent()}
      </div>
    }
  </>);
}

export default Game;
