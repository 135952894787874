import NotFoundPage from "../pages/NotFoundPage";
import Character from "../pages/character/Character";
import Home from "../pages/home/Home";
import Game from '../pages/game/Game'

export default [
  {
    path: "/",
    component: Home,
    exact: true
  },
  {
    path: "/start",
    component: Character,
    exact: true
  },
  {
    path: "/game",
    component: Game,
    exact: true
  },
  {
    component: NotFoundPage
  }
];
