import React, { useReducer } from 'react'
import { GameContext } from './gameContext'
import { gameReducer } from './gameReducer'
import {
  ANSWER_QUESTION,
  GET_PLAYERS,
  HIDE_ALERT,
  SET_PLAYER,
  SHOW_ALERT,
  SHOW_LOADER,
  START_GAME,
  SELECT_STAGE,
  TOGGLE_SOUND,
  COOKIE_SOUND,
  GET_PAGE,
  RESET_GAME,
  SHOW_FORM,
  RESET_COMMENT,
  RESET_PLAYERS, RESET_PAGE
} from '../types'
import axios from 'axios'
import config from '../../config'

import playerSelect from "../../assets/sounds/Jump.mp3"
import clickButton from "../../assets/sounds/Click.mp3"
import rules from "../../assets/sounds/Hint.mp3"
import positiveAch from "../../assets/sounds/LevelUp.mp3"
import negativeAch from "../../assets/sounds/GlassBreak.mp3"
import negativeFinal from "../../assets/sounds/Fail.mp3"
import positiveFinal from "../../assets/sounds/MissionCompleted.mp3"
import event from "../../assets/sounds/Reward.mp3"
import effect from "../../assets/sounds/Attainment.mp3"

export const GameState = ({children}) => {
  const initialState = {
    visible: false,
    loading: false,
    players: [],
    player: null,
    game: null,
    stage: null,
    sound: true,
    page: null,
    form: false
  }
  const [state, dispatch] = useReducer(gameReducer, initialState)

  const showLoader = () => dispatch({type: SHOW_LOADER})

  const toggleSound = () => {
    return dispatch({type: TOGGLE_SOUND})
  }

  const toggleForm = () => {
    return dispatch({type: SHOW_FORM})
  }

  const show = (text, type = '') => {
    dispatch({
      type: SHOW_ALERT,
      payload: {text, type}
    })
  }

  const hide = () => dispatch({type: HIDE_ALERT})
  const checkSound = () => dispatch({type: COOKIE_SOUND});
  const resetGame = () => dispatch({type: RESET_GAME});
  const resetComment = () => dispatch({type: RESET_COMMENT});
  const resetPlayers = () => dispatch({type: RESET_PLAYERS});
  const resetPage = () => dispatch({type: RESET_PAGE});

  const getPlayers = async () => {
    showLoader();
    const res = await axios.get(`${config.apiUrl}/api/players`);
    dispatch({type: GET_PLAYERS, payload: res.data});
  }

  const startGame = async (player) => {
    showLoader();
    const res = await axios.get(`${config.apiUrl}/api/start-game`, {
      params: {
        p: player,
        s: 1
      }
    });
    dispatch({ type: START_GAME, payload: res.data });
  }

  const getPage = async (page) => {
    const res = await axios.get(`${config.apiUrl}/api/page`, {
      params: {
        id: page,
      }
    });
    dispatch({type: GET_PAGE, payload: res.data});
  }

  const answerQuestion = async (hash, answer, stage) => {
    resetComment()
    const res = await axios.get(`${config.apiUrl}/api/game`, {
      params: {
        a: answer,
        h: hash,
        s: stage
      }
    });
    dispatch({type: ANSWER_QUESTION, payload: res.data});
  }

  const selectStage = async (hash, stage) => {
    showLoader();
    const res = await axios.get(`${config.apiUrl}/api/game`, {
      params: {
        h: hash,
        s: stage,
        n: 1
      }
    });
    dispatch({type: SELECT_STAGE, payload: res.data});
  }

  const setPlayer = (id) => {
    dispatch({
      type: SET_PLAYER,
      payload: id
    })
  }

  const playSound = (key) => {
    if (state.sound === false){
      return false;
    }
    let audio;
    switch (key) {
      case 'playerSelect':
        audio = new Audio(playerSelect);
        break;
      case 'clickButton':
        audio = new Audio(clickButton);
        break;
      case 'positiveAch':
        audio = new Audio(positiveAch);
        break;
      case 'negativeAch':
        audio = new Audio(negativeAch);
        break;
      case 'negativeFinal':
        audio = new Audio(negativeFinal);
        break;
      case 'positiveFinal':
        audio = new Audio(positiveFinal);
        break;
      case 'effect':
        audio = new Audio(effect);
        break;
      default:
        audio = new Audio(playerSelect);
        break;
    }
    audio.play()
  }

  return(
    <GameContext.Provider value={{
      show, hide, game: state.game, getPlayers,
      playSound, setPlayer, showLoader, startGame,
      answerQuestion, selectStage, toggleSound, getPage, toggleForm, resetPlayers,
      checkSound, resetGame, resetPage, sound: state.sound, loading: state.loading,
      players: state.players, player: state.player, page: state.page, form: state.form
    }}>
      {children}
    </GameContext.Provider>
  )
}